import _ from 'lodash';
import { compose, groupBy } from 'lodash/fp';

import { GameDescriptorEntry, PlayerEntry, StatsRow } from './helpers';
import { ValueConfig, getValueConfig, timePeriodDimensionsMap } from './schema';
import { VisualizationProps } from './visualizations';

type StatsEntry = VisualizationProps['stats'][number];

type CellEntry = {
  type: 'header' | 'data';
  value: KnownStatsValue | undefined | null;
  span: number;
  identifier: string;
  groupKey: string | undefined;
  valueConfig: ValueConfig | undefined;
};

export type PivotRow = CellEntry[];

const joinArray = (arr: KnownStatsValue[]) => arr.join('_');

const mapIdentifierInfo = (identifiers: string[]) => {
  return identifiers.map((identifier) => {
    const valueConfig = getValueConfig(identifier);
    return {
      identifier,
      fieldName: valueConfig?.fieldName || valueConfig?.name || identifier,
      valueConfig,
    };
  });
};

function createRecord<T>(keys: string[], getEmptyValue: () => T) {
  return keys.reduce((acc, key) => {
    acc[key] = getEmptyValue();
    return acc;
  }, {} as Record<string, T>);
}

type GameDescriptorStatsValue = {
  __typename: 'GameDescriptor';
  json: {
    gameId: string;
    name: string;
  };
};

type ObjectStatsValue = GameDescriptorStatsValue | PlayerEntry;

export type KnownStatsValue =
  | string
  | number
  | PlayerEntry
  | GameDescriptorEntry
  | null;

type JsonObject = {
  json: Record<string, unknown>;
};

const isJsonObject = (val: unknown): val is JsonObject => {
  if (val && typeof val === 'object' && 'json' in val) {
    return typeof (val as { json?: unknown }).json === 'object';
  }

  return false;
};

const isGraphQLObjectField = (val: unknown): val is ObjectStatsValue => {
  if (val && typeof val === 'object' && '__typename' in val) {
    return true;
  }
  return false;
};

const isPlayerStatsValue = (val: unknown): val is PlayerEntry => {
  return isGraphQLObjectField(val) && val?.__typename === 'Player';
};

const emptyTableHeading: CellEntry = {
  identifier: '',
  span: 1,
  type: 'header',
  value: '',
  groupKey: undefined,
  valueConfig: {
    Component: () => null,
    label: '',
    name: '',
    toFormattedValue: () => '',
    staticProps: {},
  },
};

type ColumnSchema = {
  children?: ColumnSchema[];
  currentDepth: number;
  groupKey?: string;
  identifier: string;
  span: number;
  value: KnownStatsValue;
};

const getSchemaFieldChildren = (
  distinctColumnSets: Record<string, Set<KnownStatsValue>>,
  values: string[],
  tail: ReturnType<typeof mapIdentifierInfo>,
  fieldIds: string[],
) => {
  if (tail.length) {
    return buildColumnSchema(distinctColumnSets, values, tail, fieldIds);
  }
  return undefined;
};

const getColumnSpan = (values: string[], children?: ColumnSchema[]) => {
  if (children) {
    return Math.max(
      children.reduce((acc, x) => acc + x.span, 0),
      1,
    );
  }
  return Math.max(values.length, 1);
};

const buildColumnSchema = (
  distinctColumnSets: Record<string, Set<KnownStatsValue>>,
  values: string[],
  columns: ReturnType<typeof mapIdentifierInfo>,
  parentFieldIds: string[],
): ColumnSchema[] => {
  const [head, ...tail] = columns;
  const headValueConfig = getValueConfig(head?.identifier);
  const distinctValues = headValueConfig
    ? distinctColumnSets[headValueConfig.fieldName || headValueConfig.name]
    : new Set<string>();

  const currentDepth = parentFieldIds.length;

  const getSelfEntries = (): ColumnSchema[] => {
    if (distinctValues) {
      return [...distinctValues].map((distinctFieldValue) => {
        const value = distinctFieldValue;

        const fieldId = valueToId(value, head.identifier);
        const fieldIds = [...parentFieldIds, fieldId];

        const children = getSchemaFieldChildren(
          distinctColumnSets,
          values,
          tail,
          fieldIds,
        );
        return {
          identifier: head.identifier,
          value,
          children,
          span: getColumnSpan(values, children),
          groupKey: children ? undefined : joinArray(fieldIds),
          currentDepth,
        };
      });
    }

    const fieldIds = [...parentFieldIds, head.fieldName];

    const children = getSchemaFieldChildren(
      distinctColumnSets,
      values,
      tail,
      fieldIds,
    );

    return [
      {
        identifier: head.identifier,
        value: head.fieldName,
        children,
        span: getColumnSpan(values, children),
        groupKey: children ? undefined : joinArray(fieldIds),
        currentDepth,
      },
    ];
  };

  return getSelfEntries();
};

const getFlatHeadingRows = (
  schema: ColumnSchema[],
  acc: Record<number, ColumnSchema[]> = {},
) => {
  for (let i = 0; i < schema.length; i++) {
    const element = schema[i];
    acc[element.currentDepth] = [...(acc[element.currentDepth] ?? []), element];

    if (element.children) {
      acc = getFlatHeadingRows(element.children, acc);
    }
  }

  return acc;
};

const NOOP_DIMENSIONS = ['all'];

type MapOptions = {
  rows: string[];
  columns: string[];
  values: string[];
  stats: StatsEntry[];
};

const mapStructure = (
  options: MapOptions,
): { tableHeadings: PivotRow[]; columnLayout: string[] } => {
  if (
    (!options.rows.length && !options.columns.length) ||
    !options.values.length
  ) {
    return {
      columnLayout: [],
      tableHeadings: [],
    };
  }

  const rowsInfo = mapIdentifierInfo(options.rows).filter(
    (a) => !NOOP_DIMENSIONS.includes(a.identifier),
  );
  const columnsInfo = mapIdentifierInfo(options.columns);

  const distinctColumnSets = options.stats.reduce(
    (acc, entry) => {
      columnsInfo.forEach((columnInfo) => {
        const { fieldName } = columnInfo;
        if (fieldName in entry) {
          const value = entry[fieldName as keyof StatsEntry];
          const set = acc[fieldName];

          if (!set || !set.has(value)) {
            set.add(value);
          }
        } else if (NOOP_DIMENSIONS.includes(columnInfo.identifier)) {
          const value = columnInfo.identifier;
          const set = acc[fieldName];
          if (!set || !set.has(value)) {
            set.add(value);
          }
        }
      });

      return acc;
    },
    createRecord(
      columnsInfo.map((a) => a.fieldName),
      () => new Set<KnownStatsValue>(),
    ),
  );

  const rowBasedTableHeadings = rowsInfo.map<CellEntry>((row) => {
    const valueConfig = getValueConfig(row.identifier);
    return {
      identifier: row.identifier,
      span: 1,
      type: 'header',
      value: valueConfig?.label,
      groupKey: undefined,
      valueConfig: undefined,
    };
  });

  const columnSchema = buildColumnSchema(
    distinctColumnSets,
    options.values,
    columnsInfo,
    [],
  );

  const mappedHeadingRows: PivotRow[] = Object.values(
    getFlatHeadingRows(columnSchema),
  ).map((a) => {
    return a.flatMap((val) => {
      const valueConfig = getValueConfig(val.identifier);
      return [
        {
          groupKey: val.groupKey,
          identifier: val.identifier,
          span: val.span,
          type: 'header',
          value: val.value,
          valueConfig,
        },
      ];
    });
  });

  const mappedHeadingRowsWithRowsFillers = mappedHeadingRows
    .filter((a) => a.some((b) => !NOOP_DIMENSIONS.includes(b.identifier)))
    .map((row) => [
      ...new Array<CellEntry>(rowsInfo.length).fill(emptyTableHeading),
      ...row,
    ]);

  const { valueHeadings, columnLayout } = (
    _.last(mappedHeadingRows) || []
  ).reduce<{
    valueHeadings: CellEntry[];
    columnLayout: string[];
  }>(
    (acc, cell) => {
      const valueHeadings: CellEntry[] = options.values.map((value) => {
        const valueConfig = getValueConfig(value);
        return {
          identifier: '',
          span: 1,
          type: 'header',
          value: valueConfig?.label || value,
          groupKey: undefined,
          valueConfig: undefined,
        };
      });

      const valueLayoutParts = options.values.map((value) => {
        return joinArray(cell.groupKey ? [cell.groupKey, value] : [value]);
      });

      return {
        valueHeadings: [...acc.valueHeadings, ...valueHeadings],
        columnLayout: [...acc.columnLayout, ...valueLayoutParts],
      };
    },
    {
      valueHeadings: [],
      columnLayout: [],
    },
  );

  const valuesHeadingRow = [...rowBasedTableHeadings, ...valueHeadings];
  const filledColumnLayout = [
    ...rowBasedTableHeadings.map(
      (h) => getValueConfig(h.identifier)?.name || h.identifier,
    ),
    ...columnLayout,
  ];

  const finalHeadingRows = [
    ...mappedHeadingRowsWithRowsFillers,
    valuesHeadingRow,
  ];

  return {
    tableHeadings: finalHeadingRows,
    columnLayout: filledColumnLayout,
  };
};

const valueToId = (value: KnownStatsValue, identifier: string) => {
  if (isPlayerStatsValue(value)) {
    return value?.id;
  }

  if (isJsonObject(value)) {
    if (value.json.gameId) {
      return value.json.gameId;
    }
  }

  if (value && typeof value === 'object') {
    // fallback to identifier if object instead of `[object Object]` as key
    console.error('Bad mapping of value', value);
    return identifier;
  }

  return value ? String(value) : identifier;
};

const createMapPart = (row: StatsRow) => (identifier: string) => {
  const valueConfig = getValueConfig(identifier);
  const valueConfigKey = valueConfig?.fieldName || valueConfig?.name;
  const value =
    (valueConfigKey && row[valueConfigKey as keyof typeof row]) ||
    row[identifier as keyof typeof row];
  return valueToId(value, identifier);
};

const mapData = (options: MapOptions) => {
  const { stats, columns, rows, values } = options;

  if (!stats || (!columns.length && !rows.length) || !values.length) {
    return [];
  }

  const uniquesByColumn: Record<string, Set<string>> = {};
  const uniquesByRow: Record<string, Set<string>> = {};

  const mappedData = stats.map((statsRow) => {
    const mapPart = createMapPart(statsRow);
    const columnsParts = columns.map(mapPart);
    const rowsParts = rows.map(mapPart);

    columns.forEach((col, i) => {
      const value = columnsParts[i];
      const set = uniquesByColumn[col] || new Set<string>();
      set.add(value);
      uniquesByColumn[col] = set;
    });

    rows.forEach((row, i) => {
      const value = rowsParts[i];
      const set = uniquesByColumn[row] || new Set<string>();
      set.add(value);
      uniquesByRow[row] = set;
    });

    const baseObj = rows.reduce<Record<string, CellEntry>>((acc, row) => {
      const valueConfig = getValueConfig(row);

      if (valueConfig) {
        const key = valueConfig.fieldName || valueConfig.name;
        const value = statsRow[key as keyof typeof statsRow];

        acc[valueConfig.name] = {
          identifier: row,
          span: 1,
          type: 'header',
          value,
          groupKey: undefined,
          valueConfig,
        };
      }

      return acc;
    }, {});

    return values.reduce((acc, value) => {
      const key = joinArray([...columnsParts, value]);
      const mapped = statsRow[value as keyof typeof statsRow];
      const valueConfig = getValueConfig(value);

      acc[key] = {
        identifier: value,
        span: 1,
        type: 'data',
        value: mapped,
        groupKey: undefined,
        valueConfig,
      };
      return acc;
    }, baseObj);
  });

  return Object.values<Record<string, CellEntry>>(
    compose(mapGroupedStatsValues, createGroupByRows(rows))(mappedData),
  );
};

const mapGroupedStatsValues = (
  groupedStats: _.Dictionary<Record<string, CellEntry>[]>,
) => {
  return Object.entries(groupedStats)
    .sort(([a], [b]) => {
      return a.toLowerCase().trim() < b.toLowerCase().trim() ? -1 : 1;
    })
    .map(([, valArr]) => Object.assign({}, ...valArr), groupedStats);
};

const createGroupByRows = (rows: string[]) => {
  return groupBy<Record<string, CellEntry>>((a) => {
    return joinArray(
      rows.map((row) => {
        const valueConfig = getValueConfig(row);

        if (valueConfig) {
          const cellEntry = a[valueConfig.name];

          // time periods should be ordered by their "raw" values, not formatted date
          if (valueConfig.name in timePeriodDimensionsMap && cellEntry.value) {
            return cellEntry.value;
          }

          return valueConfig.toFormattedValue(cellEntry.value);
        }

        console.warn('No valueConfig for', row);

        return '';
      }),
    );
  });
};

export const mapPivot = (options: MapOptions) => {
  const { columnLayout, tableHeadings } = mapStructure(options);
  const data = mapData(options);

  const mappedData = data.map((entry) => {
    return columnLayout.flatMap((placeholder) => {
      const valueConfig = getValueConfig(placeholder);

      return (valueConfig && entry[valueConfig.name]) || entry[placeholder];
    });
  });

  return {
    tableHeadings,
    mappedData,
  };
};
